import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import colors from 'styles/colors';
import Logo from 'assets/svgIcons/logo.svg';
import purpleAnimation from 'assets/animation/purple_animation.mp4';
import withSeo from 'components/hoc/withSeo';

const MobileCover = () => (
  <Box sx={{
    background: '#060E1F', height: '100%', width: '100%', overflowY: 'hidden',
  }}
  >
    <Box
      sx={{
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        paddingLeft: '20px',
        paddingRight: '32px',
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item alignItems="center">
          <Box>
            <Logo />
          </Box>
        </Grid>
      </Grid>
    </Box>
    <Grid container sx={{ width: '100%', height: '100%', marginTop: '60px' }}>
      <Grid item sx={{ width: '100%', margin: '0 50px', textAlign: 'center' }}>
        <Typography
          variant="buttonLarge"
          sx={{ color: colors.text.secondary }}
        >
          Start using the BlueQubit app on your desktop
        </Typography>
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '190%',
            position: 'absolute',
            left: '-90%',
            marginTop: '40px',
          }}
        >
          <source src={purpleAnimation} type="video/mp4" />
        </video>
      </Grid>
    </Grid>
  </Box>
);

export default withSeo(MobileCover);
