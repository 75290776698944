import { create } from 'zustand';
import {
  signOut,
  updateProfile,
  updateEmail,
  updatePassword,
  reauthenticateWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import { auth } from 'api/services/firebase';

const reauthenticate = (user) => {
  const provider = new GoogleAuthProvider();
  reauthenticateWithPopup(user, provider).then(() => {
    console.log('User re-authenticated');
  }).catch((error) => {
    console.log('authentication error', error);
  });
};

const useUserStore = create((set, get) => ({
  user: auth.currentUser,
  setUser: (user) => ({ user }),
  updateUserField: (type) => (fields) => {
    set(async ({ user }) => {
      if (user) {
        const currentTime = new Date().getTime();
        const isUserRecentLogin = (currentTime - user.metadata.lastLoginAt) / 60000 < 5;// TODO
        if (!isUserRecentLogin && type !== 'fullname') {
          reauthenticate(user);
        } else {
          switch (type) {
            case 'fullname':
              await updateProfile(user, { displayName: `${fields.firstName} ${fields.lastName}` });
              break;
            case 'email':
              await updateEmail(user, fields.email);
              break;
            case 'password':
              await updatePassword(user, fields.password);
              break;
            default:
              break;
          }
        }
        useUserStore.setState({ user });
      }
    });
  },
  getUserData: () => {
    const { user } = get();

    const [firstName, lastName] = user?.displayName?.split(' ') || ['', ''];
    const email = user?.email;
    return { firstName, lastName, email };
  },
}));

auth.onAuthStateChanged(async (user) => {
  useUserStore.setState({ user });
});

export default useUserStore;
