import {
  WHITELISTED_DOMAINS as whitelistedDomains,
  WHITELISTED_EMAILS as whitelistedEmails,
} from './constants';
import { jobInProgressStatuses } from '../constants/jobs';

export const isJobInProgress = (jobStatus) => {
  for (const statusKey in jobInProgressStatuses) {
    if (jobStatus === jobInProgressStatuses[statusKey]) {
      return true;
    }
  }

  return false;
};

export const getNameInitials = (name) => {
  const nameParts = name.split(' ');
  if (nameParts.length === 1) {
    return `${nameParts[0][0]}`;
  }
  return `${nameParts[0][0]}${nameParts[nameParts.length - 1][0]}`;
};

export const getSavedAccountMode = (userId) => {
  const accountMode = JSON.parse(localStorage.getItem(`accountMode_${userId}`));
  return accountMode?.role;
};

export const getSavedTeamId = (userId) => {
  const accountMode = JSON.parse(localStorage.getItem(`accountMode_${userId}`));
  return accountMode?.teamId;
};

export const saveAccountMode = (role, userId, teamId) => {
  if (userId) {
    localStorage.setItem(`accountMode_${userId}`, JSON.stringify({
      role,
      teamId,
    }));
  }
};

export const resetAccountMode = (userId) => {
  if (userId) {
    localStorage.removeItem(`accountMode_${userId}`);
  }
  localStorage.removeItem('accountMode');
};

export const checkEmailAddress = (email) => {
  if (whitelistedEmails.includes(email)) {
    return true;
  }

  const domainRegex = /@([a-zA-Z0-9.-]+)$/; // Regex to extract domain from email
  const match = email.match(domainRegex);

  if (match && match[1]) {
    const emailDomain = match[1];

    // Check if the email domain or its subdomains match any predefined domain
    for (const domain of whitelistedDomains) {
      const regex = new RegExp(`(?:^|\\.)${domain.replace('.', '\\.')}(?:\\.|$)`);
      if (regex.test(emailDomain)) {
        return true;
      }
    }
  }

  return false;
};

export const isJobOwner = (job, userId) => job.userId === userId;

// The isEmpty function now optionally checks for the extraEmpty value if it's provided.
const isEmpty = (value, extraEmpty) => (
  value === null
  || value === undefined
  || value === 0
  || value === ''
  || (extraEmpty !== undefined && value === extraEmpty)
);

// The isEmptyValues function accepts data and an optional extraEmpty parameter.
export const isEmptyValues = (data, extraEmpty) => Object.values(data).every((value) => isEmpty(value, extraEmpty));
