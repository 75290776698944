import { useCallback, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Avatar from '@mui/material/Avatar';
import EndIcon from 'assets/images/button-icons/AccountDownUpButton.svg';
import { getNameInitials } from 'utils/helpers';
import getColorForName from 'utils/nameColorPicker';
import UserInfo from './UserInfo';
import './account.css';
import useAccountStore from '../../../store/accountStore';
import useUserStore from '../../../store/UserStore';

const AccountAccess = ({ name, isVerified, isErrored }) => {
  const [open, setOpen] = useState(false);
  const [root, setRoot] = useState(null);
  const buttonRef = useRef(null);

  const nameInitials = name ? getNameInitials(name) : '';
  const id = open ? 'simple-popover' : undefined;
  const { avatar: avatarUrl } = useAccountStore((state) => state.account);

  const { getUserData } = useUserStore();

  const { firstName, lastName } = getUserData();

  const onMenuClick = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button
        ref={buttonRef}
        onClick={(e) => {
          setRoot(buttonRef.current);
          setOpen(true);
        }}
        type="secondary"
        endIcon={<EndIcon />}
        sx={{
          padding: '10px 20px 10px 0',
          height: '44px',
        }}
        disableRipple
      >
        <Avatar
          alt={name}
          src={avatarUrl}
          style={{
            marginRight: '10px',
            fontSize: '14px',
            backgroundColor: getColorForName(firstName, lastName),
          }}
        >
          {firstName[0]}
        </Avatar>
        <span className="accountUserName">{name}</span>
        <span className="accountUserInitials">{nameInitials}</span>
      </Button>
      <Popover
        id={id}
        open={open}
        onClose={() => {
          setOpen(false);
          setRoot(null);
        }}
        anchorEl={root}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <UserInfo isErrored={isErrored} isVerified={isVerified} onMenuClick={onMenuClick} />
      </Popover>
    </>
  );
};

export default AccountAccess;
